import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import PhoneInput, { Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAccounts } from "../../context/AccountsContext";
import { useAccountUsers } from "../../context/AccountUsersContext";
import { useAllUsers } from "../../context/AllUsersContext";
import { useMembers } from "../../context/MembersContext";
import { accountFilterObject, buildQueryParams } from "../../utils";
import { AccountsTable } from "./AccountsTable";
import { AllUsersTable } from "./AllUsersTable";
import { MemberTable } from "./MemberTable";
import { UsersTable } from "./UsersTable";

export const Table = ({ accounts, members, users, allUsers }: any) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { accountsMeta, getAccounts } = useAccounts();
  const { meta: allUsersMeta, changeAllUsersFilter } = useAllUsers();
  const { membersMeta, changeMemberFilter } = useMembers();
  const { usersMeta } = useAccountUsers();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();

  const updateUrl = (params: any) =>
    navigate(`/accounts?${buildQueryParams(params)}`);

  useEffect(() => {
    if (accountFilterObject(searchParams)) {
      getAccounts(accountFilterObject(searchParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Container
      maxW="full"
      py={{ base: "4", md: "8" }}
      px={{ base: "0", md: 8 }}
    >
      <InviteModal isOpen={isOpen} onClose={onClose} />
      <Box
        bg="bg.surface"
        boxShadow={{ base: "none", md: "sm" }}
        borderRadius={{ base: "none", md: "lg" }}
      >
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <Flex>
                <Text textStyle="lg" fontWeight="medium">
                  {!!accounts && "Accounts"}
                  {!!members && "Admins"}
                  {!!users && "Users"}
                </Text>
                {(!!users || !!members) && (
                  <IconButton
                    // onClick={() => navigate(`/account/${id}/users/new`)}
                    onClick={onOpen}
                    ms={{ base: "2" }}
                    colorScheme="blue"
                    aria-label="Search database"
                    icon={<BiPlus />}
                    isRound={true}
                  />
                )}
                {!!accounts && (
                  <IconButton
                    onClick={() => navigate(`/accounts/new`)}
                    ms={{ base: "2" }}
                    colorScheme="blue"
                    aria-label="Search database"
                    icon={<BiPlus />}
                    isRound={true}
                  />
                )}
              </Flex>

              {!!accounts && (
                <Flex>
                  <InputGroup me={{ base: "3" }} maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search by companyName"
                      onChange={(e: any) => {
                        if (e.target) {
                          updateUrl({
                            ...accountsMeta,
                            companyName: e.target.value,
                            page: 1,
                          });
                        }
                      }}
                      value={searchParams.get("companyName") || ""}
                    />
                  </InputGroup>
                  <InputGroup me={{ base: "3" }} maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search by email"
                      onChange={(e: any) => {
                        if (e.target) {
                          updateUrl({
                            ...accountsMeta,
                            email: e.target.value,
                            page: 1,
                          });
                        }
                      }}
                      value={searchParams.get("email") || ""}
                    />
                  </InputGroup>
                  <Select
                    onChange={(e: any) => {
                      updateUrl({
                        ...accountsMeta,
                        teamSize: e.target.value,
                        page: 1,
                      });
                    }}
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Team Size"
                    value={searchParams.get("teamSize") || ""}
                  >
                    <option value="1-1"> 1 - 1</option>
                    <option value="2-10"> 2 - 10</option>
                    <option value="10-50">10 - 50</option>
                    <option value="50+">50+</option>
                  </Select>
                  <Select
                    onChange={(e: any) => {
                      updateUrl({
                        ...accountsMeta,
                        accountPlan: e.target.value,
                        page: 1,
                      });
                    }}
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Account Plan"
                    value={searchParams.get("accountPlan") || ""}
                  >
                    <option value="free"> FREE</option>
                    <option value="pro">PRO</option>
                    <option value="enterprise">ENTERPRISE</option>
                  </Select>
                  <Select
                    onChange={(e: any) => {
                      updateUrl({
                        ...accountsMeta,
                        status: e.target.value,
                        page: 1,
                      });
                    }}
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Status"
                    value={searchParams.get("status") || ""}
                  >
                    <option value="BLOCKED">Blocked</option>
                    <option value="SUSPENDED">Suspended</option>
                    <option value="EXPIRING">Expired</option>
                    <option value="INACTIVE">Inactive</option>
                    <option value="ACTIVE">Active</option>
                    <option value="PENDING">Pending</option>
                  </Select>
                  <Select
                    onChange={(e: any) => {
                      updateUrl({
                        ...accountsMeta,
                        sort: e.target.value,
                        page: 1,
                      });
                    }}
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Sort"
                    value={searchParams.get("sort") || ""}
                  >
                    <option value="name">Name (A-Z)</option>
                    <option value="-name">Name (Z-A)</option>
                    <option value="createdAt">Joined Date Asc</option>
                    <option value="-createdAt">Joined Date Desc</option>
                    <option value="last_active_at">Last Login Asc</option>
                    <option value="-last_active_at">Last Login Desc</option>
                  </Select>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search by name"
                      onChange={(e: any) => {
                        updateUrl({
                          ...accountsMeta,
                          search: e.target.value || "",
                          page: 1,
                        });
                      }}
                      value={searchParams.get("search") || ""}
                    />
                  </InputGroup>
                </Flex>
              )}
              {!!allUsers && (
                <Flex>
                  <Select
                    onChange={(e: any) =>
                      changeAllUsersFilter({
                        ...allUsersMeta,
                        status: e.target.value,
                      })
                    }
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Status"
                  >
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="PENDING">PENDING</option>
                  </Select>
                  <Select
                    onChange={(e: any) =>
                      changeAllUsersFilter({
                        ...allUsersMeta,
                        role: e.target.value,
                      })
                    }
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Role"
                  >
                    <option value="OWNER">OWNER</option>
                    <option value="DEVELOPER">DEVELOPER</option>
                    <option value="ANALYST">ANALYST</option>
                  </Select>
                  <Select
                    onChange={(e: any) =>
                      changeAllUsersFilter({
                        ...allUsersMeta,
                        sort: e.target.value,
                      })
                    }
                    me={{ base: "3" }}
                    maxW="xs"
                    placeholder="Sort"
                  >
                    <option value="name">Name (A-Z)</option>
                    <option value="-name">Name (Z-A)</option>
                    <option value="createdAt">Created Date Asc</option>
                    <option value="-createdAt">Created Date Desc</option>
                    <option value="last_active_at">Last Login Asc</option>
                    <option value="-last_active_at">Last Login Desc</option>
                  </Select>
                  <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search"
                      onChange={(e: any) =>
                        changeAllUsersFilter({
                          ...allUsersMeta,
                          search: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </Flex>
              )}
            </Stack>
          </Box>
          <Box overflowX="auto">
            {members && <MemberTable members={members} />}
            {accounts && <AccountsTable accounts={accounts} />}
            {users && <UsersTable users={users} />}
            {allUsers && <AllUsersTable users={allUsers} />}
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="fg.muted" textStyle="sm">
                  {users ? (
                    <>
                      Showing{" "}
                      {usersMeta.pageLimit * (usersMeta.currentPage - 1) + 1 ||
                        0}{" "}
                      to {usersMeta.currentPage * usersMeta.pageLimit || 0} of{" "}
                      {usersMeta.total_count || 0} results
                    </>
                  ) : members ? (
                    <>
                      Showing{" "}
                      {membersMeta.pageLimit * (membersMeta.currentPage - 1) +
                        1 || 0}{" "}
                      to {membersMeta.currentPage * membersMeta.pageLimit || 0}{" "}
                      of {membersMeta.total_count || 0} results
                    </>
                  ) : !allUsersMeta ? (
                    <>
                      Showing{" "}
                      {accountsMeta.pageLimit * (accountsMeta.currentPage - 1) +
                        1 || 0}{" "}
                      to{" "}
                      {accountsMeta.currentPage * accountsMeta.pageLimit || 0}{" "}
                      of {accountsMeta.total_count || 0} results
                    </>
                  ) : accountsMeta ? (
                    <>
                      Showing{" "}
                      {accountsMeta.pageLimit * (accountsMeta.currentPage - 1) +
                        1 || 0}{" "}
                      to{" "}
                      {accountsMeta.currentPage * accountsMeta.pageLimit || 0}{" "}
                      of {accountsMeta.total_count || 0} results
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: "full", md: "auto" }}
                variant="secondary"
              >
                {}
                {users ? (
                  <>
                    <Button
                      isDisabled={usersMeta.currentPage <= 1}
                      onClick={() =>
                        changeMemberFilter({ page: usersMeta.currentPage - 1 })
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      isDisabled={
                        Math.ceil(
                          usersMeta.total_count / usersMeta.pageLimit
                        ) === usersMeta.currentPage
                      }
                      onClick={() =>
                        changeMemberFilter({ page: usersMeta.currentPage + 1 })
                      }
                    >
                      Next
                    </Button>
                  </>
                ) : members ? (
                  <>
                    <Button
                      isDisabled={membersMeta.currentPage <= 1}
                      onClick={() =>
                        changeMemberFilter({
                          page: membersMeta.currentPage - 1,
                        })
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      isDisabled={
                        Math.ceil(
                          membersMeta.total_count / membersMeta.pageLimit
                        ) === membersMeta.currentPage
                      }
                      onClick={() =>
                        changeMemberFilter({
                          page: membersMeta.currentPage + 1,
                        })
                      }
                    >
                      Next
                    </Button>
                  </>
                ) : !allUsersMeta ? (
                  <>
                    <Button
                      isDisabled={accountsMeta.currentPage <= 1}
                      onClick={() =>
                        changeAllUsersFilter({
                          page: accountsMeta.currentPage - 1,
                        })
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      isDisabled={
                        Math.ceil(
                          accountsMeta.total_count / accountsMeta.pageLimit
                        ) === accountsMeta.currentPage
                      }
                      onClick={() =>
                        changeAllUsersFilter({
                          page: accountsMeta.currentPage + 1,
                        })
                      }
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      isDisabled={accountsMeta.currentPage <= 1}
                      onClick={() => {
                        updateUrl({
                          ...accountsMeta,
                          page: accountsMeta.currentPage - 1,
                        });
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      isDisabled={
                        Math.ceil(
                          accountsMeta.total_count / accountsMeta.pageLimit
                        ) === accountsMeta.currentPage
                      }
                      onClick={() => {
                        updateUrl({
                          ...accountsMeta,
                          page: accountsMeta.currentPage + 1,
                        });
                      }}
                    >
                      Next
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

const InviteModal = ({ isOpen, onClose, user }: any) => {
  const intitalform = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    role: user?.role || "",
    phone_number: user?.phone_number,
  };
  const [userForm, setUserForm] = useState(intitalform);
  const { inviteUser } = useAccounts();
  const context = useAccountUsers();
  const { inviteAdmin, getMembers } = useMembers();
  const { id } = useParams();
  const location = useLocation();
  const toast = useToast();
  const toastId = "password-toast";
  const handlePhoneChange = (value: Value) => {
    setUserForm((prevForm) => ({
      ...prevForm,
      phone_number: value || "",
    }));
  };

  const handleSubmit = async () => {
    toast.closeAll();
    if (userForm.email && userForm.first_name && userForm.last_name) {
      if (userForm.phone_number) {
        if (!userForm.phone_number.startsWith("+")) {
          userForm.phone_number = `+91${userForm.phone_number}`;
        }
      }

      if (id) {
        // invite user for account
        if (toast.isActive(toastId)) {
          toast.close(toastId);
        }
        await inviteUser(id!, userForm);
        const res: any = await context.inviteUser(id!, userForm);

        if (res?.success) {
          setUserForm(intitalform);
          window.location.reload();
        } else {
          let msg =
            res === "HttpException: USER ALREADY EXISTS"
              ? "User Already Exists"
              : res === "HttpException: ACCOUNT CONFIG EXCEEDS AGENTS"
              ? "User Limit Exceeds This Month"
              : res;
          toast({
            id: toastId,
            title: msg,
            status: "error",
            position: "top-right",
            isClosable: true,
            duration: 3000,
          });
        }
      } else {
        // invite admin
        userForm["role"] = "SUPERADMIN";
        if (!userForm.phone_number) {
          delete userForm["phone_number"];
        }
        const res: any = await inviteAdmin(userForm);
        if (res?.success) {
          getMembers();
          onClose();
        } else {
          let msg =
            res === "HttpException: USER ALREADY EXISTS"
              ? "User Already Exists"
              : res;
          toast({
            id: toastId,
            title: msg,
            status: "error",
            position: "top-right",
            isClosable: true,
            duration: 3000,
          });
        }
      }
    } else {
      toast({
        title: "Fill All The Fields",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Invite {location.pathname.includes("members") ? "Admin" : "User"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box as="form" bg="bg.surface">
            <Stack spacing="5" py={{ base: "5", md: "6" }}>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    onChange={(e: any) =>
                      setUserForm({ ...userForm, first_name: e.target.value })
                    }
                    value={userForm.first_name}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    onChange={(e: any) =>
                      setUserForm({ ...userForm, last_name: e.target.value })
                    }
                    value={userForm.last_name}
                  />
                </FormControl>
              </Stack>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>Email</FormLabel>
                  <Input
                    onChange={(e: any) =>
                      setUserForm({ ...userForm, email: e.target.value })
                    }
                    value={userForm.email}
                  />

                  {/* {user?<Tooltip label="Email cant be updated" placement='top'><Input  value={userForm.email} disabled/></Tooltip>:<Input onChange={(e: any) => setUserForm({...userForm, email: e.target.value})} value={userForm.email} /> } */}
                </FormControl>
                {!location.pathname.includes("members") && (
                  <FormControl id="lastName">
                    <FormLabel>Role</FormLabel>
                    <Select
                      onChange={(e: any) =>
                        setUserForm({ ...userForm, role: e.target.value })
                      }
                      value={userForm.role}
                      placeholder="Select Role"
                    >
                      <option value="OWNER">Owner</option>
                      <option value="DEVELOPER">Developer</option>
                      <option value="ANALYST">Analyst</option>
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <FormControl id="street">
                <FormLabel>Phone number(Optional)</FormLabel>
                <PhoneInput
                  defaultCountry="IN"
                  value={userForm.phone_number}
                  onChange={handlePhoneChange}
                  className="signUpPhone"
                  style={{ width: "100%" }}
                />
              </FormControl>
            </Stack>
          </Box>
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
          <Button onClick={handleSubmit} type="submit" variant="primary">
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { InviteModal };
